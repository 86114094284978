import React, {useState, useEffect} from 'react';
import bridge from '@vkontakte/vk-bridge';
import {View, ScreenSpinner, AdaptivityProvider, Root, AppRoot, ConfigProvider, SplitLayout, SplitCol} from '@vkontakte/vkui';
import {useRouter, useLocation, useRoute, withRouter} from '@happysanta/router';
import '@vkontakte/vkui/dist/vkui.css';
import "./App.scss";

import Welcome from "./panels/welcome";
import Home from "./panels/home";
import AppParams from "./utils/AppParams";
import request from "./utils/Request"

import {
    PAGE_HOME,
    PAGE_HOME_V2,
    PAGE_WELCOME,
    PANEL_HOME,
    PANEL_HOME_V2,
    PANEL_SPEND,
    PANEL_TASK, PANEL_TASK_V2,
    PANEL_TASKS_V2,
    PANEL_WELCOME,
    VIEW_MAIN,
    VIEW_V2
} from "./router";
import {ContextState} from "./ContextState";
import Task from "./panels/task";
import Spend from "./panels/spend";
import HomeV2 from "./panels/v2/home";
import TasksV2 from "./panels/v2/tasks";
import TaskV2 from "./panels/v2/task";

const App = () => {
    const [scheme, setScheme] = useState()
    const [popout, setPopout] = useState(<ScreenSpinner size='large'/>);
    const {dispatch} = React.useContext(ContextState);


    const location = useLocation();
    const router = useRouter();

    useEffect(() => {
        let isMounted = true;
        bridge
            .send('VKWebAppGetUserInfo')
            .then((user) => {
                return request('/api/user', {
                    body: user
                })
            })
            .then((user) => {
                if (isMounted) {
                    dispatch({
                        type: 'setState',
                        payload: {
                            user
                        }
                    });
                    if (!!user.welcomeShown) {
                        router.replacePage(PAGE_HOME_V2);

                    } else {
                        router.replacePage(PAGE_WELCOME);
                    }
                }
            })
            .finally(() => {
                setPopout(null);
            });
        return () => {
            isMounted = false;
        }
    }, []);

    useEffect(() => {

        if (location.getViewId() === VIEW_MAIN) {
            setScheme('delo');
        }else {
            setScheme('delo-v2');
        }

    }, [location.getViewId()]);

    //const VersionedView = withVersion(View);

    return (
        <ConfigProvider scheme={scheme} transitionMotionEnabled={false}>
            <AdaptivityProvider>
                <AppRoot>
                    <Root activeView={location.getViewId()} className={"platform__" + AppParams.vk_platform}>
                        <View id={VIEW_MAIN}
                              history={location.getViewHistory(VIEW_MAIN)}
                              activePanel={location.getViewActivePanel(VIEW_MAIN)}>
                            <Home id={PANEL_HOME}/>
                            <Welcome id={PANEL_WELCOME}/>
                            <Task id={PANEL_TASK}></Task>
                            <Spend id={PANEL_SPEND}/>
                        </View>
                        <View id={VIEW_V2}
                              history={location.getViewHistory(VIEW_V2)}
                              activePanel={location.getViewActivePanel(VIEW_V2)}>
                            <HomeV2 id={PANEL_HOME_V2}/>
                            <TasksV2 id={PANEL_TASKS_V2}/>
                            <TaskV2 id={PANEL_TASK_V2}/>
                        </View>
                    </Root>
                </AppRoot>
            </AdaptivityProvider>
        </ConfigProvider>
    );
}

export default withRouter(App);
