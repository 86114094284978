import bridge from "@vkontakte/vk-bridge";
import md5 from "md5"

const replaceUnicode = (s) => s.replace(/[^\x20-\x7F]/g, x => "\\u" + ("000" + x.codePointAt(0).toString(16)).slice(-4));

const Request = (uri, params = {}) => {
    params['headers'] = params['headers'] ?? {};
    params['headers']['Vk-Auth-Uri'] = location.search;
    if (params.body && params.body) {
        params['headers']['Content-Type'] = 'application/json';
        if (typeof params.body === 'object') {
            params.body = JSON.stringify(params.body);
        }
        params['method'] = params['method'] || 'post';

        return bridge.send("VKWebAppCreateHash", {
            payload: md5(replaceUnicode(params.body))
        }).then((data) => {
            const {sign, ts} = data;
            params.body = JSON.stringify({sign, ts, ...JSON.parse(params.body)});
            return fetch(uri, params)
        }).then((resp) => {
            if (resp.headers.get('Content-Type') === 'application/json') {
                return resp.json();
            } else {
                return {};
            }
        })
            .catch((err) => {
                console.error(err);
            });
    }
    return fetch(uri, params)
        .then((resp) => {
            if (resp.headers.get('Content-Type') === 'application/json') {
                return resp.json();
            } else {
                return {};
            }
        })
        .catch((err) => {
            console.error(err);
        });
    ;
}

export default Request;