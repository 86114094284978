import React from 'react';
import PropTypes from 'prop-types';

import {Panel, PanelHeader, Div, Button} from '@vkontakte/vkui';
import {useRouter} from '@happysanta/router';

import './style.scss';
import { PAGE_HOME_V2} from "../../router";
import vkLogo from "./vkedulogo.png";
import drugoeDelo from "./drugoedelo.svg";
import videoSrc from "./video.png";
import videoX2Src from "./video-x2.png";
import eduImage from "./edu.svg";
import request from "../../utils/Request";
import bridge from "@vkontakte/vk-bridge";

const Welcome = props => {

    const [view, setView] = React.useState('view-1');
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const [loadingButtonName, setLoadingButtonName] = React.useState(null);

    const router = useRouter();

    const startTest = () => {
        setButtonLoading(true);
        return request('/api/user', {
            body: {
                'params': {
                    'welcomeShown': true
                }
            }
        }).then(() => {

        }).finally(() => {
            setButtonLoading(false);
            setLoadingButtonName(null);
            router.pushPage(PAGE_HOME_V2)
        })

    }

    return <Panel id={props.id}>
        <PanelHeader separator={false} before={<img src={vkLogo} className="logo" alt="VK"/>}>

        </PanelHeader>
        {view === 'view-1' && <>
            <div>
                <img src={drugoeDelo} className="drugoe-delo" alt="Другое дело"/>
            </div>
            <Div className="text-wrap">
                <p>
                    Привет! У&nbsp;нас есть несколько полезных лайфхаков по&nbsp;прокачке soft skills и&nbsp;подарки
                    для&nbsp;тебя
                </p>
            </Div>
            <Div className="button-wrap">
                <Button size="xl" onClick={() => {
                    setView('view-2')
                }}>Круто!</Button>
            </Div>
        </>}
        {view === 'view-2' && <>
            <div>
                <picture>
                    <source srcSet={videoX2Src} media="(min-width: 390px)"/>
                    <img src={videoSrc} className="video" alt="Другое дело"/>
                </picture>
            </div>
            <Div className="text-wrap">
                <p>
                    Смотри видео, правильно отвечай на вопросы, получай баллы и обменивай их&nbsp;на&nbsp;подарки.
                </p>
            </Div>
            <Div className="button-wrap">
                <Button size="xl" onClick={() => {
                    setView('view-3')
                }}>Поехали!</Button>
            </Div>
        </>}
        {view === 'view-3' && <>
            <div>
                <img src={eduImage} className="edu-image" alt="Образование"/>
            </div>
            <Div className="text-wrap">
                <p>
                    Прежде чем&nbsp;начать, подпишись на&nbsp;сообщество VK Джуниор, чтобы&nbsp;первым узнавать о&nbsp;бесплатных курсах, образовательных
                    программах, вебинарах, соревнованиях, чемпионатах и&nbsp;олимпиадах!
                </p>
            </Div>
            <Div className="button-wrap">
                <Button loading={buttonLoading && loadingButtonName === 'subscribe'} size="xl" onClick={() => {
                    setLoadingButtonName('subscribe');
                    bridge
                        .send("VKWebAppJoinGroup", {"group_id": 92204627})
                        .catch(() => {
                        })
                        .finally(startTest);
                }}>Подписаться</Button><br/>
                <Button size="xl" onClick={() => {
                    setLoadingButtonName('skip');
                    startTest();
                }} loading={buttonLoading && loadingButtonName === 'skip'}>Пропустить!</Button>
            </Div>
        </>}

        <Div className="pattern">

        </Div>
    </Panel>
};

Welcome.propTypes = {
    id: PropTypes.string.isRequired,
};

export default Welcome;
