import React, {useState} from 'react';
import bridge from '@vkontakte/vk-bridge';
import {PanelHeader, PanelHeaderBack, PanelSpinner, Panel, Gallery, Div, Group, Button, Spinner} from "@vkontakte/vkui";
import {ContextState} from "../../ContextState";
import {useRouter, useParams} from "@happysanta/router";
import request from "../../utils/Request";
import "./style.scss";
import {PAGE_HOME, PAGE_TASK_V2, PAGE_TASKS_V2} from "../../router";
import {PlayerComponent} from '../task/player';
import {useTasks} from "../hooks/useTasks";

const TaskV2 = ({id}) => {

    const {state: {watchedVideos = [], user}, dispatch} = React.useContext(ContextState);
    const {tasks, loading: tasksLoading} = useTasks();
    const {id: taskId} = useParams();
    const task = tasks.find((element) => element.id === taskId);


    const [videoWatchingEnded, setVideoWatchingEnded] = React.useState(false);
    const [panelLoading, setPanelLoading] = React.useState(true);
    const [questionData, setQuestionData] = React.useState(false);
    const [quizEnded, setQuizEnded] = React.useState(false);
    const [quizPassed, setQuizPassed] = React.useState(false);
    const [scoreUsed, setScoreUsed] = React.useState(false);
    const [variantsDisabled, setVariantsDisabled] = React.useState(false);
    const [testingMode, setTestingMode] = React.useState(false);
    const [loadingVariant, setLoadingVariant] = React.useState(0);
    const [currentTestScore, setCurrentTestScore] = React.useState(0);
    const [completeError, setCompleteError] = React.useState(null);
    const [questionCount, setQuestionCount] = useState(0);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const [taskVideosLeft, setTaskVideosLeft] = React.useState([...task?.videos || []]);
    const [currentVideo, setCurrentVideo] = React.useState();

     const router = useRouter();


    React.useEffect(() => {
        let isMounted = true;
        request('/api/question', {
            'body': {
                task: taskId
            }
        }).then(({question, variants, finished, passed, success, count}) => {
            if (!finished) {
                success && setQuestionData({...question, 'variants': variants})
            }
            setQuestionCount(count);
            if (passed && finished) {
                return request('/api/complete', {
                    'body': {
                        task: taskId
                    }
                }).then((json) => {
                    return new Promise((resolve, reject) => {
                        resolve({...json, finished, passed})
                    })
                })
            }

            return new Promise((resolve) => {
                resolve({success: false, passed: false, finished, used: false});
            })

        }).then(({success, passed, finished, used, error = ''}) => {
            if (success) {
                setQuizPassed(passed);
            }
            if (error) {
                setCompleteError(error);
                console.error(error);
            }

            setScoreUsed(used);
            setQuizEnded(finished);

        }).finally(() => {
            setPanelLoading(false);
        })

        return () => {
            isMounted = false;
        }
    }, [taskId]);

    React.useEffect(() => {

        const body = document.querySelector('body');
        if (testingMode){
            body.style.overflow = "auto";
        }else {
            body.style.overflow = "hidden";
        }

    }, [testingMode]);


    React.useEffect(() => {

        if (taskVideosLeft.length > 0) {
            setCurrentVideo(taskVideosLeft.at(0))
        } else {
            setVideoWatchingEnded(true);
        }
    }, [taskVideosLeft]);

    const onVideoEnded = () => {
        if (taskVideosLeft.length) {
            setTaskVideosLeft(prev => prev.slice(1));
        }
    }

    const setWatchedVideos = (ids) => {
        dispatch({
            type: 'setState',
            payload: {
                watchedVideos: ids
            }
        })
    }


    const replyToQuestion = (ev) => {
        const variantId = ev.currentTarget.dataset.id;
        setVariantsDisabled(true);
        setLoadingVariant(variantId);
        request('/api/question', {
            'body': {
                variant: variantId,
                task: taskId
            }
        }).then(({question, variants, finished, passed, success, currentIndex, score}) => {
            if (!finished) {
                success && setQuestionData({...question, 'variants': variants})
            }
            if (score) {
                setCurrentTestScore(score);
            }
            setCurrentQuestionIndex(currentIndex);
            if (passed && finished) {
                return request('/api/complete', {
                    'body': {
                        task: taskId
                    }
                }).then((json) => {
                    return new Promise((resolve, reject) => {
                        resolve({...json, finished, passed})
                    })
                })
            }

            return new Promise((resolve) => {
                resolve({success: false, passed: false, finished, used: false});
            })

        }).then(({success, passed, finished, used, error = ''}) => {
            if (success) {
                setQuizPassed(passed);
            }
            setScoreUsed(used);
            setQuizEnded(finished);
            if (finished && !passed) {
                const ids = watchedVideos.reduce((accumulator, currentValue) => {
                    if (!taskVideosLeft.find((v) => v.id === parseInt(currentValue))) {
                        accumulator.push(parseInt(currentValue));
                    }
                    return accumulator;
                }, []);
                setWatchedVideos(ids);
            } else if (finished && passed) {
                user.passedTasks.push(taskId)
                dispatch({
                    type: 'setState',
                    payload: {
                        user
                    }
                })
            }

            if (error) {
                setCompleteError(error);
                console.error(error);
            }
        }).finally(() => {
            setVariantsDisabled(false);
            setLoadingVariant(0);
        })
    }


    const playVideos = () => {
        // if restart play
        if (videoWatchingEnded) {
            setTaskVideosLeft([...task.videos]);
            setVideoWatchingEnded(false);
            setCurrentTestScore(0);
            setCurrentQuestionIndex(0);
            setTestingMode(false);
            setQuizEnded(false);
        }
    }

    return <Panel id={id}>
        <PanelHeader separator={false}
                     before={<PanelHeaderBack onClick={() => router.pushPage(PAGE_TASKS_V2)}
                                              label={"Вернуться к списку видео"}/>}>

        </PanelHeader>
        {(panelLoading || tasksLoading) ? <PanelSpinner size="large"/> : <>
            {quizEnded ? (quizPassed ? <>
                <Div className="panel-title">
                    <h1>Ты молодец! Хочешь улучшить навыки ещё больше? </h1>
                </Div>
                <Div className="ended-text">
                    <p>Все&nbsp;ответы были верными&nbsp;— начислили тебе {task.score}&nbsp;баллов. А&nbsp;чтобы прокачать гибкие навыки ещё&nbsp;больше&nbsp;—
                        пройди бесплатный курс на&nbsp;VK&nbsp;Education.</p>
                </Div>
                <Div className="button-wrap --no-wrap">
                    <Button size="l" onClick={() => {
                        window.open('https://vk.cc/cvmyv1', '_parent')
                    }}>Пройти курс по гибким навыкам</Button>

                    <Button size="l" onClick={() => {
                        router.pushPage(PAGE_TASKS_V2);
                    }}>Продолжить</Button>
                </Div>
            </> : <>
                <Div className="panel-title">
                    <h1>{`${currentTestScore > 0 ? 'Не всё прошло гладко. Но не стоит сдаваться!' : 'Попробуй изучить тему внимательнее'}`}</h1>
                </Div>
                <Div className="ended-text">
                    {currentTestScore > 0 ?
                        <p>Ответы были верными на&nbsp;{currentTestScore} из&nbsp;{questionCount} вопросов{user.passedTasks.includes(taskId) ? 'nbsp;—' +
                            ' начислили тебе {task.score}&nbsp;баллов' : ''}. Посмотри видео ещё раз или&nbsp;пройди
                            бесплатный курс по&nbsp;гибким навыкам
                            на&nbsp;VK&nbsp;Education.</p> :
                        <p>Все&nbsp;ответы оказались неверными. Посмотри видео ещё&nbsp;раз&nbsp;или&nbsp;пройди бесплатный курс по&nbsp;гибким навыкам
                            на&nbsp;VK&nbsp;Education.</p>
                    }
                </Div>
                <Div className="button-wrap --no-wrap">
                    <Button size="l" onClick={playVideos}>Посмотреть видео ещё раз</Button>
                    <Button size="l" onClick={() => {
                        window.open('https://vk.cc/cvmyv1', '_parent')
                    }}>Пройти курс по гибким навыкам</Button>
                </Div>
            </>) : <>
                <Group className="task-video">
                    {!testingMode && currentVideo &&
                        <>
                            <div className="button-wrap --upper">
                                <Button size="m" mode={videoWatchingEnded?'primary':'outline'} disabled={!videoWatchingEnded}   stretched={true}
                                        onClick={() => setTestingMode(true)}>{videoWatchingEnded ? 'Пройти тест' : `До\u00A0теста осталось просмотреть ${taskVideosLeft.length}\u00A0видео из\u00A0${task.videos.length}-х`}</Button>
                            </div>
                            <div
                                 className="video-container"
                                 style={{
                                     padding: 0
                                 }}>

                                {currentVideo ? <div className="iframe-container">
                                    <PlayerComponent src={`${currentVideo.path}&autoplay=${videoWatchingEnded?0:1}&playsinline=1&loop=0`} props={{
                                        allow: "playsinline; encrypted-media; fullscreen;",
                                        frameBorder: "0"
                                    }} events={{
                                        ended: onVideoEnded
                                    }}/>

                                </div> : <>
                                    <img
                                        src={currentVideo.poster ? currentVideo.poster : "/assets/posters/" + currentVideo.id + ".png"}/>
                                    <i className="play-button" onClick={playVideos}/>
                                    <Button size="l" onClick={playVideos} stretched={true}>{currentVideo.title}</Button>
                                </>}


                            </div>

                        </>
                    }


                    {testingMode && videoWatchingEnded && questionData && <Div className="question-block">

                        {questionCount > 0 && <ul className="progress-dots">{
                            [...Array(questionCount).keys()].map((v) => <li key={v}
                                                                            className={`${v < currentQuestionIndex ? 'passed' : ''} ${v === currentQuestionIndex ? 'current' : ''}`}>{v + 1}</li>)}</ul>}

                        <div className="question">
                            {questionData.caption}
                        </div>
                        <div className="variants">
                            {questionData.variants.map((variant) => <Button size="l"
                                                                            key={"variant_" + variant.id}
                                                                            data-id={variant.id}
                                                                            disabled={variantsDisabled}
                                                                            onClick={replyToQuestion}
                                                                            loading={(loadingVariant === variant.id)}
                            >{variant.caption}</Button>)}
                        </div>
                    </Div>}
                </Group>
            </>}
        </>}

    </Panel>
}

export default TaskV2;