import React, {useState} from 'react';
import {Panel, PanelHeader, Group, Gallery, Div, ScreenSpinner, Button} from '@vkontakte/vkui';
import {PAGE_HOME, PAGE_HOME_V2, PAGE_TASK, PAGE_TASK_V2, PAGE_TASKS_V2} from "../../router";
import {useRouter} from '@happysanta/router';
import vkLogo from "../welcome/vkedulogo.svg";
import request from "../../utils/Request";
import {ContextState} from "../../ContextState";
import "./style.scss";
import ddLogo from "./img/ddlogo2.svg"
import homeImage from "./img/home.png";

const HomeV2 = ({id}) => {

    const {dispatch, state: {user, tasks = []}} = React.useContext(ContextState);

    const router = useRouter();

    React.useEffect(() => {
        let isMounted = true;
        if (tasks && tasks.length) {
            return;
        }
        request('/api/task')
            .then((tasks) => {
                dispatch({
                    type: 'setState',
                    payload: {
                        tasks
                    }
                })
            })

        return () => {
            isMounted = false;
        }

    }, []);


    return <Panel id={id}>
        <PanelHeader separator={false} before={<img src={vkLogo} className="logo" alt="VK"/>}/>

        <div className="panel-title">
            <h1>Прокачай гибкие навыки</h1>
        </div>
        <div className="panel__images" style={{backgroundImage: `url(${homeImage})`}}>

        </div>
        <div className="panel__description">
            Смотри видео, проходи
            тесты и&nbsp;получай баллы<sup className="icon-coin"></sup>
        </div>
        <div className="dd-logo">
            <a href="#"><img src={ddLogo} alt="Другое дело"/></a>
        </div>
        <div className="panel__button-go">
            <Button onClick={() => router.pushPage(PAGE_TASKS_V2)} size="l" mode="primary" appearance="accent">Поехали!</Button>
        </div>
        <div className="panel__aggreement">
            * Нажимая кнопку «Поехали!»,
            ты соглашаешься с <a
            href="https://vk.com/legal">политикой
            обработки данных</a>
        </div>
        <div className="panel__button-archive">
            <Button onClick={() => router.pushPage(PAGE_HOME)} size="s" mode="primary" appearance="neutral">К другим навыкам</Button>
        </div>
    </Panel>
};


export default HomeV2;
