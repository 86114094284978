import {Page, Router} from '@happysanta/router';

export const VIEW_MAIN = 'view_main'
export const VIEW_V2 = 'view_v2'

export const PAGE_HOME = '/home';
export const PAGE_HOME_V2 = '/v2/home';
export const PANEL_HOME = 'panel_home';
export const PANEL_HOME_V2 = 'panel_home_v2';

export const PAGE_WELCOME = '/welcome';
export const PANEL_WELCOME = 'panel_welcome';

export const PAGE_SPEND = '/spend';
export const PANEL_SPEND = 'panel_spend';

export const PAGE_TASK = '/task/:id([0-9]+)';
export const PAGE_TASK_V2 = '/v2/task/:id([0-9]+)';
export const PANEL_TASK = 'panel_task';
export const PANEL_TASK_V2 = 'panel_task_v2';

export const PANEL_TASKS_V2 = 'panel_tasks_v2';
export const PAGE_TASKS_V2 = '/v2/tasks';

const routes = {
    [PAGE_HOME]: new Page(PANEL_HOME, VIEW_MAIN),
    [PAGE_WELCOME]: new Page(PANEL_WELCOME, VIEW_MAIN),
    [PAGE_TASK]: new Page(PANEL_TASK, VIEW_MAIN),
    [PAGE_SPEND]: new Page(PANEL_SPEND, VIEW_MAIN),
    [PAGE_HOME_V2]: new Page(PANEL_HOME_V2, VIEW_V2),
    [PAGE_TASK_V2]: new Page(PANEL_TASK_V2, VIEW_V2),
    [PAGE_TASKS_V2]: new Page(PANEL_TASKS_V2, VIEW_V2),
};

export const router = new Router(routes);

router.start();