import cherkasova from "./cherkasova.png"
import didliakov from "./didliakov.png"
import sardarian from "./sardarian.png"
import lubenko from "./lubenko.png"
import cherkashina from "./cherkashina.png"
import maxim from "./maxim.png"

export default {
    'cherkasova.png' : cherkasova,
    'didliakov.png' : didliakov,
    'sardarian.png' : sardarian,
    'lubenko.png' : lubenko,
    'cherkashina.png' : cherkashina,
    'maxim.png' : maxim,
}