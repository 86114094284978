import {useState, useEffect, useContext} from 'react';
import {ContextState} from "../../ContextState";
import request from "../../utils/Request";


export function useTasks() {

    const {dispatch, state: {tasks = [], tasksLoaded = false}} = useContext(ContextState);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const findData = (prop, value) => {
        return data.reduce((previousValue, currentValue) => {
            if (currentValue[prop] === value) {
                previousValue.push(currentValue)
            }

            return previousValue;
        }, [])
    }

    useEffect(() => {
        if (tasksLoaded) {
            setLoading(false);
            return;
        }
        let isMounted = true;
        request('/api/task')
            .then((tasks) => {
                dispatch({
                    type: 'setState',
                    payload: {
                        tasks,
                        tasksLoaded: tasks.length > 0
                    }
                });
            })
            .catch((err) => setError(err))
            .finally(() => setLoading(false))

        return () => {
            isMounted = false;
        }


    }, []);

    return {loading, tasks, error, findTasks: findData};
}
