import React, {useState} from 'react';
import bridge from '@vkontakte/vk-bridge';
import {PanelHeader, PanelHeaderBack, PanelSpinner, Panel, Gallery, Div, Group, Button, Spinner} from "@vkontakte/vkui";
import {ContextState} from "../../ContextState";
import {useRouter, useParams} from "@happysanta/router";
import request from "../../utils/Request";
import "./style.scss";
import {PAGE_HOME} from "../../router";
import {Player} from './player';

const Task = ({id}) => {

    const {state: {watchedVideos = [], user, forceWatch = [], tasks = []}, dispatch} = React.useContext(ContextState);


    const {id: taskId} = useParams();
    const task = tasks.find((element) => element.id === taskId);


    const [videoWatchingEnded, setVideoWatchingEnded] = React.useState(false);
    const [panelLoading, setPanelLoading] = React.useState(true);
    const [questionData, setQuestionData] = React.useState(false);
    const [quizEnded, setQuizEnded] = React.useState(false);
    const [quizPassed, setQuizPassed] = React.useState(false);
    const [scoreUsed, setScoreUsed] = React.useState(false);
    const [variantsDisabled, setVariantsDisabled] = React.useState(false);
    const [loadingVariant, setLoadingVariant] = React.useState(0);
    const [playingVideo, setPlayingVideo] = React.useState(null);
    const [player, setPlayer] = React.useState(null);
    const [completeError, setCompleteError] = React.useState(null);
    const [slideIndex, setSlideIndex] = useState(Math.max(0, watchedVideos.filter((wv) => task.videos.find((v) => v.id === wv)).length - 1));

    const iframeRef = React.useRef();
    const galleryRef = React.useRef();

    const router = useRouter();


    React.useEffect(() => {
        if (tasks && tasks.length) {
            return;
        }
        let isMounted = true;
        request('/api/task')
            .then((tasks) => {
                dispatch({
                    type: 'setState',
                    payload: {
                        tasks
                    }
                });
            })

        return () => {
            isMounted = false;
        }

    }, []);

    React.useEffect(() => {
        let isMounted = true;
        request('/api/question', {
            'body': {
                task: taskId
            }
        }).then(({question, variants, finished, passed, success}) => {
            if (!finished) {
                success && setQuestionData({...question, 'variants': variants})
            }

            if (passed && finished) {
                return request('/api/complete', {
                    'body': {
                        task: taskId
                    }
                }).then((json) => {
                    return new Promise((resolve, reject) => {
                        resolve({...json, finished, passed})
                    })
                })
            }

            return new Promise((resolve) => {
                resolve({success: false, passed: false, finished, used: false});
            })

        }).then(({success, passed, finished, used, error = ''}) => {
            if (success) {
                setQuizPassed(passed);
            }
            if (error) {
                setCompleteError(error);
                console.error(error);
            }

            setScoreUsed(used);
            setQuizEnded(finished);

        }).finally(() => {
            setPanelLoading(false);
        })

        return () => {
            isMounted = false;
        }
    }, [taskId]);


    React.useEffect(() => {
        if (playingVideo && iframeRef.current) {
            const player = Player(iframeRef.current);
            player.on('started', () => {
                (watchedVideos.indexOf(playingVideo.id) === -1) && watchedVideos.push(playingVideo.id);
                setWatchedVideos(watchedVideos);
            });
            player.play();
            setPlayer(player);

        }

        window.addEventListener('fullscreenchange', onFullScreen);

        return () => {
            window.removeEventListener('fullscreenchange', onFullScreen);
        };

    }, [playingVideo, iframeRef.current]);


    const onFullScreen = (ev) => {
        if (!document.fullscreenElement) {
           setTimeout(() => {
               galleryRef.current.querySelector('.Gallery__layer').style.setProperty('transform', 'translateX(-' + (slideIndex * window.document.body.clientWidth) + 'px)');
           }, 10)
        }
    }

    const setWatchedVideos = (ids) => {
        dispatch({
            type: 'setState',
            payload: {
                watchedVideos: ids
            }
        })
    }

    const replyToQuestion = (ev) => {
        const variantId = ev.currentTarget.dataset.id;
        setVariantsDisabled(true);
        setLoadingVariant(variantId);
        request('/api/question', {
            'body': {
                variant: variantId,
                task: taskId
            }
        }).then(({question, variants, finished, passed, success}) => {
            if (!finished) {
                success && setQuestionData({...question, 'variants': variants})
            }

            if (passed && finished) {
                return request('/api/complete', {
                    'body': {
                        task: taskId
                    }
                }).then((json) => {
                    return new Promise((resolve, reject) => {
                        resolve({...json, finished, passed})
                    })
                })
            }

            return new Promise((resolve) => {
                resolve({success: false, passed: false, finished, used: false});
            })

        }).then(({success, passed, finished, used, error = ''}) => {
            if (success) {
                setQuizPassed(passed);
            }
            setScoreUsed(used);
            setQuizEnded(finished);
            if (finished && !passed) {
                const ids = watchedVideos.reduce((accumulator, currentValue) => {
                    if (!task.videos.find((v) => v.id == currentValue)) {
                        accumulator.push(currentValue);
                    }
                    return accumulator;
                }, []);
                setWatchedVideos(ids);
            } else if (finished && passed) {
                user.passedTasks.push(taskId)
                dispatch({
                    type: 'setState',
                    payload: {
                        user
                    }
                })
            }

            if (error) {
                setCompleteError(error);
                console.error(error);
            }
        }).finally(() => {
            setVariantsDisabled(false);
            setLoadingVariant(0);
        })
    }


    const playVideo = (id) => {
        const video = task.videos.find((v) => v.id == id);
        setPlayingVideo(video);
    }

    const onNextVideoClick = () => {
        player && player.destroy();
        setPlayingVideo(null);
        setVideoWatchingEnded(task.videos.length === watchedVideos.filter((wv) => task.videos.find((v) => v.id === wv)).length);
        if (!videoWatchingEnded) {
            const nextIndex = slideIndex + 1;
            setSlideIndex(nextIndex);
            task.videos.length > nextIndex && playVideo(task.videos[nextIndex].id)
        }

    }


    return <Panel id={id}>
        <PanelHeader separator={false}
                     before={<PanelHeaderBack onClick={() => router.pushPage(PAGE_HOME)}
                                              label={"Вернуться к списку видео"}/>}>

        </PanelHeader>

        {panelLoading ? <PanelSpinner size="large"/> : <>
            {(quizEnded && forceWatch.indexOf(taskId) === -1) ? (quizPassed ? <>
                <Div className="panel-title --passed">
                    {scoreUsed ? <h3>
                        За это видео уже <a onClick={() => {
                        bridge.send("VKWebAppOpenApp", {"app_id": 7785085, "location": "marketplace"});
                    }}>начислено {task.score}&nbsp;<i className="icon-coin"/></a>
                    </h3> : <h3>
                        Урра! Ты заработал {task.score}&nbsp;<i className="icon-coin"/>
                        {!completeError && <>
                            <br/> Мы их&nbsp;уже перечислили <a onClick={() => {
                            bridge.send("VKWebAppOpenApp", {"app_id": 7785085, "location": "marketplace"});
                        }}>на&nbsp;твой счёт в&nbsp;«Другое дело»</a>
                        </>}

                    </h3>}

                </Div>
                <Div className="ended-text">
                    <p>Понравилось видео? Ещё&nbsp;больше полезного контента про&nbsp;старт карьеры в&nbsp;IT
                        ты&nbsp;можешь найти в&nbsp;сообществе VK
                        Джуниор</p>
                </Div>
                <Div className="button-wrap">
                    <Button size="xl" onClick={() => {
                        bridge
                            .send("VKWebAppJoinGroup", {"group_id": 92204627})
                            .catch(() => {
                            })
                            .finally(() => {
                                router.pushPage(PAGE_HOME);
                            });
                    }}>Подписаться</Button>
                    <br/>
                    <Button size="xl" onClick={() => {
                        if (quizPassed) {
                            forceWatch.push(task.id);
                            dispatch({
                                type: 'setState',
                                payload: {
                                    forceWatch
                                }
                            })
                        }
                        router.pushPage(PAGE_HOME);
                    }}>Продолжить</Button>
                </Div>
                <Div className="pattern">

                </Div>
            </> : <>
                <Div className="panel-title">
                    <h3>Упсссс!</h3>
                </Div>
                <Div className="ended-text">
                    <p>Посмотри видео ещё раз и ответь на вопросы.</p>
                </Div>
                <Div className="button-wrap">
                    <Button size="xl" onClick={() => {
                        router.pushPage(PAGE_HOME)
                    }}>Вернуться</Button>
                </Div>
                <Div className="pattern">

                </Div>
            </>) : <>
                <Div className="panel-title">
                    <h3>{task.title}</h3>
                </Div>
                <Group>
                    {task && task.videos && <Gallery slideWidth="100%"
                                                     bullets={false} slideIndex={slideIndex}
                                                     getRef={galleryRef} align="center"
                    >
                        {task.videos.map((video, _index) =>
                            <div key={"video_" + video.id}
                                 className="video-poster"
                                 style={{
                                     padding: 0
                                 }}>

                                {playingVideo && playingVideo.id === video.id ? <div className="iframe-container">
                                    {player && (player.getState() === 'unstarted' || player.getState() === 'uninited') &&
                                        <Spinner size="large" style={{margin: "20px 0"}}/>

                                    }
                                    <iframe src={playingVideo.path + "&js_api=1&autoplay=1&playsinline=1"} ref={iframeRef}
                                            allow="autoplay; playsinline; encrypted-media; fullscreen;"
                                            frameBorder="0" allowFullScreen></iframe>
                                </div> : <>
                                    <img
                                        src={video.poster ? video.poster : "/assets/posters/" + video.id + ".png"}/>
                                    <i className="play-button" onClick={() => {
                                        playVideo(video.id)
                                    }}/>
                                </>}
                            </div>)}
                    </Gallery>}
                    <Div className="gallery-progress">
                        {task.videos.map((video, i) => <div key={"bullet" + video.id}
                                                            className={watchedVideos.indexOf(video.id) > -1 ? '--active' : ''}>
                            <span className="checkbox"></span>
                            <span className="counter">{i + 1}</span>
                        </div>)}
                    </Div>

                    {!videoWatchingEnded && <Div className="video-next-button-wrap"><Button size="xl" onClick={onNextVideoClick}
                                                                                            disabled={!watchedVideos.find((wv) => {
                                                                                                if (!playingVideo) {
                                                                                                    return false;
                                                                                                }
                                                                                                return wv === playingVideo.id;
                                                                                            })} align="center">Далее</Button></Div>}

                    {videoWatchingEnded && questionData && <Div className="question-block">
                        <p>Закрепим знания? Пройди тест и&nbsp;за&nbsp;каждый правильный ответ получи баллы.
                            И&nbsp;их&nbsp;можно обменять
                            на&nbsp;подарки</p>
                        <div className="question">
                            <span>Вопрос №{questionData.position}</span>
                            {questionData.caption}
                        </div>
                        <div className="variants">
                            {questionData.variants.map((variant) => <Button appearance="neutral" size="l"
                                                                            key={"variant_" + variant.id}
                                                                            data-id={variant.id}
                                                                            disabled={variantsDisabled}
                                                                            onClick={replyToQuestion}
                                                                            after={(loadingVariant == variant.id) ? <Spinner size="small"
                                                                                                                             style={{margin: "20px 0"}}/> : null}
                            >{variant.caption}</Button>)}
                        </div>
                    </Div>}
                </Group>
            </>}
        </>}

    </Panel>
}

export default Task;