import React from 'react';
import PropTypes from 'prop-types';

import {Panel, PanelHeader, Div, Button, PanelHeaderBack} from '@vkontakte/vkui';

import './style.scss';
import drugoeDelo from "./drugoedelo.svg";
import {ContextState} from "../../ContextState";
import {PAGE_HOME} from "../../router";
import {useRouter} from "@happysanta/router";


const Spend = props => {

    const {state:{user}} = React.useContext(ContextState);
    const router = useRouter();

    return <Panel id={props.id}>
        <PanelHeader separator={false}
                     before={<PanelHeaderBack onClick={() => router.pushPage(PAGE_HOME)} label={"Вернуться к списку видео"}/>}>

        </PanelHeader>
        <div>
            <img src={drugoeDelo} className="drugoe-delo" alt="Другое дело"/>
        </div>
        <Div className="text-wrap">
               <p>
                   У&nbsp;тебя накопилось <strong>{user.score}</strong><i className="icon-coin"/><br />
                   Перечислить их&nbsp;на&nbsp;твой счёт<br />
                   в&nbsp;«Другое дело»?
               </p>
        </Div>
        <Div className="button-wrap">
            <Button size="xl">Перечислить</Button>
        </Div>
        <Div className="pattern">

        </Div>
    </Panel>
};

Spend.propTypes = {
    id: PropTypes.string.isRequired,
};

export default Spend;
