import React, {useState} from 'react';

import {Panel, PanelHeader, Group, Gallery, Div, ScreenSpinner} from '@vkontakte/vkui';
import {PAGE_TASK} from "../../router";
import {useRouter} from '@happysanta/router';
import vkLogo from "../welcome/vkedulogo.png";
import request from "../../utils/Request";
import {ContextState} from "../../ContextState";
import "./style.scss";
import photos from "./photos";

const Home = ({id}) => {

    const [loading, setLoading] = useState(false);
    const {dispatch, state: {user, tasks = []}} = React.useContext(ContextState);
    const [currentTaskScore, setCurrentTaskScore] = useState(tasks.length ? tasks[0].score : 50);

    const router = useRouter();

    React.useEffect(() => {
        let isMounted = true;
        if (tasks && tasks.length) {
            return;
        }
        setLoading(true)
        request('/api/task')
            .then((tasks) => {
                dispatch({
                    type: 'setState',
                    payload: {
                        tasks
                    }
                })
                if (isMounted) {
                    tasks[0] && setCurrentTaskScore(tasks[0].score);
                    setLoading(false);
                }

            })

        return () => {
            isMounted = false;
        }

    }, []);


    return <Panel id={id}>
        <PanelHeader separator={false} before={<img src={vkLogo} className="logo" alt="VK"/>}>

        </PanelHeader>
        <Div className="panel-title">
            <h3>Ответь на&nbsp;вопросы по&nbsp;видео<br/> и&nbsp;получи <span>{currentTaskScore}</span><i className="icon-coin"/></h3>
        </Div>
        <Group>
            {loading ? <ScreenSpinner size='large'/> : <Gallery slideWidth="300px" bullets="tasks" isDraggable={true} onChange={(current) => {
                tasks[current] && setCurrentTaskScore(tasks[current].score)
            }}>
                {tasks.filter(({version}) => version === 1).map((item) => <Div key={"item_" + item.id} className="task-item" onClick={() => {
                    router.pushPage(PAGE_TASK, {id: item.id})
                }}>
                    <div className="left">
                        <div className="task-score">
                            <span className={(Array.isArray(user.passedTasks) && user.passedTasks.indexOf(item.id) > -1 ? 'checked' : '') + ' check'}></span>
                            <span className="value">+{item.score}</span>
                        </div>
                        <div className="task-title">{item.title}</div>
                        <div className="task-author">{item.author}</div>
                    </div>
                    <div className="right">
                        <div className="task-photo"><img src={photos[item.photoPath]}/></div>
                        <div className="task-bar"></div>
                    </div>
                </Div>)}
            </Gallery>}

        </Group>
    </Panel>
};


export default Home;
